import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { createEmployee } from '@/apis/employees'
import useNotification from '@/hooks/useNotification'
import routes from '@/routes'
import { CreateEmployeeRequest, CreateEmployeeResponse } from '@/types/apis/employees'

export const useCreateEmployees = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const openNotification = useNotification()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<CreateEmployeeResponse>()

  const create = useCallback(
    async ({ name, code }: CreateEmployeeRequest) => {
      try {
        setLoading(true)
        const res = await createEmployee({ name, code })
        setResponse(res)
        openNotification({ description: t('pages.employees.create.createSuccessfully') })
        navigate(routes.employees.path)
      } finally {
        setLoading(false)
      }
    },
    [navigate, openNotification, t]
  )

  return {
    loading,
    createEmployee: create,
    response,
  }
}
