import { TablePaginationConfig } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { UniformsPageHeader, UniformsTable } from '@/components/pages/Uniforms/List'
import routes from '@/routes'

import { useUniforms } from './Uniforms.hook'

const Uniforms = () => {
  const navigate = useNavigate()
  const { getUniforms, getUniformsLoading, getUniformsResponse, page, pageSize, setPage } = useUniforms()

  const dataSource = getUniformsResponse?.items ?? []
  const totalItems = getUniformsResponse?.meta.totalItems || 0

  const handleChangePage = (config: TablePaginationConfig) => {
    setPage(config.current ?? page)
    navigate({
      pathname: routes.uniforms.path,
      search: `?page=${config.current ?? page}`,
    })
  }

  useEffect(() => {
    getUniforms().then()
  }, [getUniforms])

  return (
    <>
      <UniformsPageHeader />
      <UniformsTable {...{ dataSource, getUniformsLoading, page, totalItems, pageSize }} onChange={handleChangePage} />
    </>
  )
}

Uniforms.displayName = 'Uniforms'
export default Uniforms
