export const update = {
  form: {
    text: 'Text',
    startAt: 'Start At',
    endAt: 'End At',
    public: 'Public',
    question: 'Question',
    translations: 'Translations',
    employees: 'Employees',
    update: 'Update',
    requireText: 'Please input announcement Text field!',
    requireStartAt: 'Please input announcement Start At field!',
    requireEndAt: 'Please input announcement End At field!',
    updateSuccessfully: 'Update announcement successfully!',
    requireSelect: 'Please select',
  },
  pageHeader: {
    title: 'Update Announcement',
  },
}
