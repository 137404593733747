import React from 'react'

import { CreateEmployeeForm, CreateEmployeePageHeader } from '@/components/pages/Employees/Create'
import { useCreateEmployees } from '@/pages/Employees/Create/Create.hooks'

const CreateEmployee = () => {
  const { createEmployee, loading } = useCreateEmployees()

  return (
    <>
      <CreateEmployeePageHeader />
      <CreateEmployeeForm {...{ loading }} onSubmit={createEmployee} />
    </>
  )
}

CreateEmployee.displayName = 'CreateEmployee'
export default CreateEmployee
