import { Button, DatePicker, Form, Input, Select, Spin, Switch } from 'antd'
import moment from 'moment'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { dateTime } from '@/configs'
import { GetAnnouncementResponse, UpdateAnnouncementRequest } from '@/types/apis/announcements'
import { GetEmployeesResponse } from '@/types/apis/employees'
import { GetLanguagesResponse } from '@/types/apis/languages'

type LanguageCode = string

export type UpdateAnnouncementFormValue = Pick<
  UpdateAnnouncementRequest,
  'text' | 'startAt' | 'endAt' | 'isPublic' | 'isQuestion' | 'employeeIds' | 'translations'
> & {
  language: Record<LanguageCode, string>
}

type Props = {
  updateLoading: boolean
  getLoading: boolean
  getAnnouncementResponse: GetAnnouncementResponse | undefined
  getEmployeesResponse: GetEmployeesResponse | undefined
  getLanguagesResponse: GetLanguagesResponse | undefined
  onSubmit: (formValues: UpdateAnnouncementFormValue) => void
}

const UpdateAnnouncementForm = ({
  updateLoading,
  getLoading,
  getAnnouncementResponse,
  getEmployeesResponse,
  getLanguagesResponse,
  onSubmit,
}: Props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const dataSourceEmployees = getEmployeesResponse?.items ?? []
  const dataEmployees = dataSourceEmployees.map((item) => ({ label: item?.name, value: item?.id }))

  const dataSourceLanguages = useMemo(() => getLanguagesResponse ?? [], [getLanguagesResponse])
  const languageInputs = useMemo(
    () =>
      dataSourceLanguages.map((item, index) => (
        <div key={index}>
          <Form.Item
            label={item.name}
            name={['translations', index, 'id']}
            initialValue={getAnnouncementResponse?.translations.find((t) => t.language.id === item.id)?.id ?? null}
            hidden
          />
          <Form.Item
            label={item.name}
            name={['translations', index, 'text']}
            initialValue={getAnnouncementResponse?.translations.find((t) => t.language.code === item.code)?.text ?? ''}
          >
            <Input />
          </Form.Item>
          <Form.Item label={item.name} name={['translations', index, 'language']} initialValue={item} hidden />
        </div>
      )),
    [dataSourceLanguages, getAnnouncementResponse]
  )

  useEffect(() => {
    if (getAnnouncementResponse) {
      const currentEmployees = getAnnouncementResponse?.employees.map((item) => item.id)
      form.setFieldsValue({
        text: getAnnouncementResponse.text,
        startAt: moment(getAnnouncementResponse.startAt),
        endAt: moment(getAnnouncementResponse.endAt),
        isQuestion: getAnnouncementResponse.isQuestion,
        employeeIds: currentEmployees,
        translations: getAnnouncementResponse.translations,
        isPublic: getAnnouncementResponse.isPublic,
      })
    }
  }, [form, getAnnouncementResponse])

  return (
    <Spin spinning={getLoading}>
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Form.Item
          label={t('components.pages.announcements.update.form.text')}
          name="text"
          rules={[{ required: true, message: t('components.pages.announcements.update.form.requireText') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('components.pages.announcements.update.form.public')}
          name="isPublic"
          valuePropName="checked"
        >
          <Switch checkedChildren={t('common.yes')} unCheckedChildren={t('common.no')} />
        </Form.Item>

        <Form.Item
          label={t('components.pages.announcements.update.form.question')}
          name="isQuestion"
          valuePropName="checked"
        >
          <Switch checkedChildren={t('common.yes')} unCheckedChildren={t('common.no')} />
        </Form.Item>

        <Form.Item
          label={t('components.pages.announcements.update.form.startAt')}
          name="startAt"
          rules={[{ required: true, message: t('components.pages.announcements.update.form.requireStartAt') }]}
        >
          <DatePicker format={dateTime.dateFormat} />
        </Form.Item>

        <Form.Item
          label={t('components.pages.announcements.update.form.endAt')}
          name="endAt"
          rules={[{ required: true, message: t('components.pages.announcements.update.form.requireEndAt') }]}
        >
          <DatePicker format={dateTime.dateFormat} />
        </Form.Item>

        <Form.Item label={t('components.pages.announcements.update.form.employees')} name="employeeIds">
          <Select
            options={dataEmployees}
            mode="multiple"
            placeholder={t('components.pages.announcements.update.form.requireSelect')}
            style={{ width: '100%' }}
          />
        </Form.Item>

        <h3>{t('components.pages.announcements.update.form.translations')}</h3>
        {languageInputs}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={updateLoading}>
            {t('components.pages.announcements.update.form.update')}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  )
}

UpdateAnnouncementForm.displayName = 'UpdateAnnouncementForm'
export default UpdateAnnouncementForm
