import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Menu, PageHeader } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import routes from '@/routes'

const EmployeesPageHeader = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => navigate(routes.employeesImport.path)}>
        {t('components.pages.employees.list.pageHeader.csvImport')}
      </Menu.Item>
    </Menu>
  )

  return (
    <PageHeader
      ghost={false}
      title={t('components.pages.employees.list.pageHeader.title')}
      extra={[
        <Dropdown.Button
          key="action"
          type="primary"
          icon={<DownOutlined />}
          overlay={menu}
          onClick={() => navigate(routes.createEmployee.path)}
        >
          {t('components.pages.employees.list.pageHeader.new')}
        </Dropdown.Button>,
      ]}
    />
  )
}

EmployeesPageHeader.displayName = 'EmployeesPageHeader'
export default EmployeesPageHeader
