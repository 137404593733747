export const list = {
  table: {
    id: 'Id',
    code: 'Code',
    name: 'Name',
    size: 'Size',
    attendanceLogs: 'Attendance logs',
  },
  pageHeader: {
    title: 'Uniforms',
    new: 'New',
    csvImport: 'CSV Import',
  },
}
