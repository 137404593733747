import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { deleteAttendanceLog, getAttendanceLogs, updateAttendanceLogReturn } from '@/apis/attendanceLogs'
import { API_LIMIT } from '@/constants/api'
import useNotification from '@/hooks/useNotification'
import useQueryPage from '@/hooks/useQueryPage'
import {
  AttendanceLogReturnRequest,
  AttendanceLogReturnResponse,
  GetAttendanceLogsResponse,
} from '@/types/apis/attendanceLogs'
import { DeleteEmployeeRequest } from '@/types/apis/employees'

export const useAttendanceLogs = () => {
  const { t } = useTranslation()
  const openNotification = useNotification()
  const currentPage = useQueryPage()
  const [page, setPage] = useState(currentPage)
  const [getAttendanceLogsLoading, setGetAttendanceLogsLoading] = useState(false)
  const [deleteAttendanceLogLoading, setDeleteAttendanceLogLoading] = useState(false)
  const [getAttendanceLogsResponse, setAttendanceLogsResponse] = useState<GetAttendanceLogsResponse>()
  const [returnLoading, setreturnLoading] = useState<boolean>(false)
  const [returnUniformResponse, setReturnUniformResponse] = useState<AttendanceLogReturnResponse>()
  const get = useCallback(async () => {
    try {
      setGetAttendanceLogsLoading(true)
      const res = await getAttendanceLogs({ page, limit: API_LIMIT })
      setAttendanceLogsResponse(res)
    } finally {
      setGetAttendanceLogsLoading(false)
    }
  }, [page])

  const del = useCallback(
    async (request: DeleteEmployeeRequest) => {
      try {
        setDeleteAttendanceLogLoading(true)
        await deleteAttendanceLog(request)

        if (getAttendanceLogsResponse?.items) {
          const newEmployees = getAttendanceLogsResponse.items.filter((item) => item.id !== request.id)
          setAttendanceLogsResponse({ ...getAttendanceLogsResponse, items: newEmployees })
        }
      } finally {
        setDeleteAttendanceLogLoading(false)
      }
    },
    [getAttendanceLogsResponse]
  )

  const update = useCallback(
    async (request: AttendanceLogReturnRequest) => {
      try {
        setreturnLoading(true)

        const res = await updateAttendanceLogReturn(request)
        setReturnUniformResponse(res)
        openNotification({ description: t('pages.attendanceLogs.returnedAt.returnSuccessfully') })
      } finally {
        setreturnLoading(false)
      }
    },
    [openNotification, t]
  )

  return {
    getAttendanceLogsLoading,
    deleteAttendanceLogLoading,
    getAttendanceLogs: get,
    deleteAttendanceLog: del,
    updateReturnUniform: update,
    returnLoading,
    returnUniformResponse,
    page,
    pageSize: API_LIMIT,
    setPage,
    getAttendanceLogsResponse,
  }
}
