import { Button, Form, Input } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { CreateEmployeeRequest } from '@/types/apis/employees'

type Props = {
  loading: boolean
  onSubmit: (formValues: CreateEmployeeRequest) => void
}

const CreateEmployeeForm = ({ loading, onSubmit }: Props) => {
  const { t } = useTranslation()

  return (
    <Form layout="vertical" onFinish={onSubmit}>
      <Form.Item
        label={t('components.pages.employees.create.form.name')}
        name="name"
        rules={[{ required: true, message: t('components.pages.employees.create.form.requireName') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('components.pages.employees.create.form.code')}
        name="code"
        rules={[{ required: true, message: t('components.pages.employees.create.form.requireCode') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('components.pages.employees.create.form.submit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

CreateEmployeeForm.displayName = 'CreateEmployeeForm'
export default CreateEmployeeForm
