import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { createUniform } from '@/apis/uniforms'
import useNotification from '@/hooks/useNotification'
import routes from '@/routes'
import { CreateUniformRequest, CreateUniformResponse } from '@/types/apis/uniforms'

export const useCreateUniforms = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const openNotification = useNotification()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<CreateUniformResponse>()

  const create = useCallback(
    async (request: CreateUniformRequest) => {
      try {
        setLoading(true)
        const res = await createUniform(request)
        setResponse(res)
        openNotification({ description: t('pages.uniforms.create.createSuccessfully') })
        navigate(routes.uniforms.path)
      } finally {
        setLoading(false)
      }
    },
    [navigate, openNotification, t]
  )

  return {
    loading,
    createUniform: create,
    response,
  }
}
