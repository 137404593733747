import { Button, Checkbox, Popconfirm, Space, Table, TablePaginationConfig } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import routes from '@/routes'
import { Announcement } from '@/types'

type Props = {
  dataSource: Announcement[] | undefined
  page: number
  pageSize: number
  totalItems: number
  getAnnouncementsLoading: boolean
  onChange: (config: TablePaginationConfig) => void
  onDelete: (id: Announcement['id']) => void
}

const AnnouncementsTable = ({
  dataSource,
  getAnnouncementsLoading,
  page,
  pageSize,
  totalItems,
  onChange,
  onDelete,
}: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [visibleConfirmDelete, setVisibleComfirmDelete] = useState<Record<string, boolean>>({})
  const columns = [
    {
      title: t('components.pages.announcements.list.table.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('components.pages.announcements.list.table.text'),
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: t('components.pages.announcements.list.table.startAt'),
      dataIndex: 'startAt',
      key: 'startAt',
      render: (text: string, record: Announcement) => moment(record.startAt).format('L'),
    },
    {
      title: t('components.pages.announcements.list.table.endAt'),
      dataIndex: 'endAt',
      key: 'endAt',
      render: (text: boolean, record: Announcement) => moment(record.endAt).format('L'),
    },
    {
      title: t('components.pages.announcements.list.table.public'),
      dataIndex: 'isPublic',
      key: 'isPublic',
      render: (text: boolean, record: Announcement) => <Checkbox checked={record.isPublic} />,
    },
    {
      title: t('components.pages.announcements.list.table.published'),
      dataIndex: 'isPublished',
      key: 'isPublished',
      render: (text: boolean, record: Announcement) => <Checkbox checked={record.isPublished} />,
    },
    {
      title: t('components.pages.announcements.list.table.question'),
      dataIndex: 'isQuestion',
      key: 'isQuestion',
      render: (text: boolean, record: Announcement) => <Checkbox checked={record.isQuestion} />,
    },
    {
      title: t('components.pages.announcements.list.table.action'),
      dataIndex: 'action',
      key: 'action',
      align: 'right' as const,
      // eslint-disable-next-line
      render: (text: string, record: Announcement) => {
        return (
          <Space size="middle">
            <Button type="link" size="small" onClick={() => goToEditAnnouncementPage(record.id)}>
              {t('components.pages.announcements.list.table.update')}
            </Button>
            <Popconfirm
              id={record.id.toString()}
              visible={!!visibleConfirmDelete[record.id]}
              title={t('components.pages.announcements.list.table.confirmDelete')}
              okText={t('common.yes')}
              cancelText={t('common.no')}
              onConfirm={() => onDelete(record.id)}
              onCancel={() => setVisibleComfirmDelete({})}
            >
              <Button type="link" size="small" danger onClick={() => setVisibleComfirmDelete({ [record.id]: true })}>
                {t('components.pages.announcements.list.table.delete')}
              </Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  const goToEditAnnouncementPage = (id: Announcement['id']) => {
    navigate(`${routes.announcements.path}/${id}`)
  }
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      loading={getAnnouncementsLoading}
      pagination={{
        pageSize,
        current: page,
        total: totalItems,
      }}
      onChange={onChange}
      rowKey="id"
    />
  )
}

AnnouncementsTable.displayName = 'AnnouncementsTable'
export default AnnouncementsTable
