import { TablePaginationConfig } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { EmployeesPageHeader, EmployeesTable } from '@/components/pages/Employees/List'
import routes from '@/routes'
import { Employee } from '@/types'

import { useEmployees } from './Employees.hooks'

const Employees = () => {
  const navigate = useNavigate()
  const { getEmployees, getEmployeesLoading, deleteEmployee, getEmployeesResponse, page, pageSize, setPage } =
    useEmployees()

  const dataSource = getEmployeesResponse?.items ?? []
  const totalItems = getEmployeesResponse?.meta.totalItems || 0

  const handleChangePage = (config: TablePaginationConfig) => {
    setPage(config.current ?? page)
    navigate({
      pathname: routes.employees.path,
      search: `?page=${config.current ?? page}`,
    })
  }

  const handleDeleteEmployee = async (id: Employee['id']) => {
    await deleteEmployee({ id })
  }

  useEffect(() => {
    getEmployees().then()
  }, [getEmployees])

  return (
    <>
      <EmployeesPageHeader />
      <EmployeesTable
        {...{ dataSource, getEmployeesLoading, page, pageSize, totalItems }}
        onChange={handleChangePage}
        onDelete={handleDeleteEmployee}
      />
    </>
  )
}

Employees.displayName = 'Employees'
export default Employees
