import { TablePaginationConfig } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AnnouncementsPageHeader, AnnouncementsTable } from '@/components/pages/Announcements/List'
import routes from '@/routes'
import { Announcement } from '@/types'

import { useAnnouncements } from './Announcements.hook'

const Announcements = () => {
  const navigate = useNavigate()
  const {
    getAnnouncements,
    deleteAnnouncement,
    deleteAnnouncementLoading,
    getAnnouncementsLoading,
    getAnnouncementsResponse,
    page,
    pageSize,
    setPage,
  } = useAnnouncements()

  const dataSource = getAnnouncementsResponse?.items ?? []
  const totalItems = getAnnouncementsResponse?.meta.totalItems || 0

  const handleChangePage = (config: TablePaginationConfig) => {
    setPage(config.current ?? page)
    navigate({
      pathname: routes.announcements.path,
      search: `?page=${config.current ?? page}`,
    })
  }

  const handleDeleteAnnouncement = async (id: Announcement['id']) => {
    await deleteAnnouncement({ id })
  }

  useEffect(() => {
    getAnnouncements().then()
  }, [getAnnouncements])

  return (
    <>
      <AnnouncementsPageHeader />
      <AnnouncementsTable
        {...{
          dataSource,
          deleteAnnouncement,
          deleteAnnouncementLoading,
          getAnnouncementsLoading,
          page,
          pageSize,
          totalItems,
        }}
        onChange={handleChangePage}
        onDelete={handleDeleteAnnouncement}
      />
    </>
  )
}

Announcements.displayName = 'Announcements'
export default Announcements
