import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { UpdateEmployeeForm, UpdateEmployeePageHeader } from '@/components/pages/Employees/Update'
import { UpdateEmployeeFormValue } from '@/components/pages/Employees/Update/Form'
import { useGetEmployee, useUpdateEmployees } from '@/pages/Employees/Update/Update.hooks'

const UpdateEmployee = () => {
  const params = useParams<{ id: string }>()
  const { updateEmployee, loading: updateLoading } = useUpdateEmployees()
  const { getEmployee, loading: getLoading, response: getEmployeeResponse } = useGetEmployee()

  const employeeId = Number(params.id)

  const handleSubmitForm = async (formValues: UpdateEmployeeFormValue) => {
    await updateEmployee({ ...formValues, id: employeeId })
  }

  useEffect(() => {
    getEmployee({ id: employeeId }).then()
  }, [employeeId, getEmployee, params.id])

  return (
    <>
      <UpdateEmployeePageHeader />
      <UpdateEmployeeForm {...{ updateLoading, getLoading, getEmployeeResponse }} onSubmit={handleSubmitForm} />
    </>
  )
}

UpdateEmployee.displayName = 'UpdateEmployee'
export default UpdateEmployee
