import { VFC } from 'react'

import { ImportEmployeesPageHeader, ImportEmployeesUploadCvs } from '@/components/pages/Employees/Import'
import { useImportEmployee } from '@/pages/Employees/Import/Import.hooks'

const ImportEmployee: VFC = () => {
  const { importEmployee, loading } = useImportEmployee()

  return (
    <>
      <ImportEmployeesPageHeader />
      <ImportEmployeesUploadCvs loading={loading} onUpload={importEmployee} />
    </>
  )
}

ImportEmployee.displayName = 'ImportEmployee'
export default ImportEmployee
