import React from 'react'

import { CreateAnnouncementForm, CreateAnnouncementPageHeader } from '@/components/pages/Announcements/Create'
import { useCreateAnnouncements } from '@/pages/Announcements/Create/Create.hooks'

const CreateAnnouncement = () => {
  const { createAnnouncement, loading } = useCreateAnnouncements()

  return (
    <>
      <CreateAnnouncementPageHeader />
      <CreateAnnouncementForm {...{ loading }} onSubmit={createAnnouncement} />
    </>
  )
}

CreateAnnouncement.displayName = 'CreateAnnouncement'
export default CreateAnnouncement
