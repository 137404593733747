import { PageHeader } from 'antd'
import { useTranslation } from 'react-i18next'

const CreateUniFormPageHeader = () => {
  const { t } = useTranslation()
  return <PageHeader ghost={false} title={t('components.pages.uniforms.create.pageHeader.title')} />
}

CreateUniFormPageHeader.displayName = 'CreateUniformPageHeader'
export default CreateUniFormPageHeader
