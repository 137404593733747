import { Button, DatePicker, Form, Input, Switch } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { dateTime } from '@/configs'
import { CreateAnnouncementRequest } from '@/types/apis/announcements'

type Props = {
  loading: boolean
  onSubmit: (formValues: CreateAnnouncementRequest) => void
}

const CreateAnnouncementForm = ({ loading, onSubmit }: Props) => {
  const { t } = useTranslation()
  return (
    <Form layout="vertical" onFinish={onSubmit}>
      <Form.Item
        label={t('components.pages.announcements.create.form.text')}
        name="text"
        rules={[{ required: true, message: t('components.pages.announcements.create.form.requireText') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('components.pages.announcements.create.form.startAt')}
        name="startAt"
        rules={[{ required: true, message: t('components.pages.announcements.create.form.requireStartAt') }]}
      >
        <DatePicker format={dateTime.dateFormat} />
      </Form.Item>

      <Form.Item
        label={t('components.pages.announcements.create.form.endAt')}
        name="endAt"
        rules={[{ required: true, message: t('components.pages.announcements.create.form.requireEndAt') }]}
      >
        <DatePicker format={dateTime.dateFormat} />
      </Form.Item>

      <Form.Item
        label={t('components.pages.announcements.create.form.isPublic')}
        valuePropName="checked"
        name="isPublic"
      >
        <Switch checkedChildren={t('common.yes')} unCheckedChildren={t('common.no')} />
      </Form.Item>

      <Form.Item
        label={t('components.pages.announcements.create.form.isQuestion')}
        valuePropName="checked"
        name="isQuestion"
      >
        <Switch checkedChildren={t('common.yes')} unCheckedChildren={t('common.no')} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('components.pages.announcements.create.form.summit')}
        </Button>
      </Form.Item>
    </Form>
  )
}

CreateAnnouncementForm.displayName = 'CreateAnnouncementForm'
export default CreateAnnouncementForm
