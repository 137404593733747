import { Button, Popconfirm, Space, Table, TablePaginationConfig } from 'antd'
import moment from 'moment'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ModalReturnUniform } from '@/components/pages/AttendanceLogs/ModalReturn'
import { AttendanceLogReturnFormValue } from '@/components/pages/AttendanceLogs/ModalReturn/Modal'
import routes from '@/routes'
import { AttendanceLog } from '@/types'

type Props = {
  dataSource: AttendanceLog[] | undefined
  getAttendanceLogsLoading: boolean
  returnLoading: boolean
  page: number
  pageSize: number
  totalItems: number
  onChange: (config: TablePaginationConfig) => void
  onDelete: (id: AttendanceLog['id']) => void
  onReturnSubmit: (formValues: AttendanceLogReturnFormValue, id: AttendanceLog['id']) => Promise<void>
}

const AttendanceLogsTable = ({
  dataSource,
  getAttendanceLogsLoading,
  returnLoading,
  page,
  pageSize,
  totalItems,
  onChange,
  onDelete,
  onReturnSubmit,
}: Props) => {
  const [visibleConfirmDelete, setVisibleConfirmDelete] = useState<Record<string, boolean>>({})
  const [isShowModal, setIsShowModal] = useState<boolean>(false)
  const [idReturn, setIdReturn] = useState<number>()
  const handelCloseModal = () => {
    setIsShowModal(false)
  }
  const { t } = useTranslation()
  const columns = [
    {
      title: t('components.pages.attendanceLogs.list.table.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('components.pages.attendanceLogs.list.table.employee'),
      dataIndex: 'employee',
      key: 'employee',
      render: (text: string, record: AttendanceLog) => (
        <Link to={`${routes.employees.path}/${record.employee?.id}`}>{record.employee?.name}</Link>
      ),
    },
    {
      title: t('components.pages.attendanceLogs.list.table.terminal'),
      dataIndex: 'terminal',
      key: 'terminal',
      render: (text: string, record: AttendanceLog) => (
        <Link to={`${routes.terminals.path}/${record.terminal?.id}`}>{record.terminal?.name}</Link>
      ),
    },
    {
      title: t('components.pages.attendanceLogs.list.table.uniform'),
      dataIndex: 'uniform',
      key: 'uniform',
      render: (text: string, record: AttendanceLog) => (
        <Link to={`${routes.uniforms.path}/${record.uniform?.id}`}>{record?.uniform?.code}</Link>
      ),
    },
    {
      title: t('components.pages.attendanceLogs.list.table.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text: string, record: AttendanceLog) => moment(record.createdAt).format('L'),
    },
    {
      title: t('components.pages.attendanceLogs.list.table.returnedAt'),
      dataIndex: 'returnedAt',
      key: 'returnedAt',
      render: (text: string, record: AttendanceLog) => moment(record.returnedAt).format('L LT'),
    },
    {
      title: t('components.pages.attendanceLogs.list.table.action'),
      dataIndex: 'action',
      key: 'action',
      align: 'right' as const,
      render: (text: string, record: AttendanceLog) => (
        <Space size="middle">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              setIsShowModal(true)
              setIdReturn(record.id)
            }}
          >
            {t('components.pages.attendanceLogs.list.table.btnReturn')}
          </Button>
          <ModalReturnUniform
            returnId={idReturn || record.id}
            onClose={handelCloseModal}
            isShow={isShowModal}
            onReturnSubmit={onReturnSubmit}
            returnLoading={returnLoading}
          />
          <Popconfirm
            id={record.id.toString()}
            visible={!!visibleConfirmDelete[record.id]}
            title={t('components.pages.attendanceLogs.list.table.confirmDelete')}
            okText={t('common.yes')}
            cancelText={t('common.no')}
            onCancel={() => setVisibleConfirmDelete({})}
            onConfirm={() => onDelete(record.id)}
          >
            <Button type="link" size="small" danger onClick={() => setVisibleConfirmDelete({ [record.id]: true })}>
              {t('components.pages.attendanceLogs.list.table.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]
  return (
    <Table
      rowKey="id"
      dataSource={dataSource}
      columns={columns}
      loading={getAttendanceLogsLoading}
      pagination={{
        pageSize,
        current: page,
        total: totalItems,
      }}
      onChange={onChange}
    />
  )
}

AttendanceLogsTable.displayName = 'AttendanceLogsTable'
export default AttendanceLogsTable
