import { Button, PageHeader } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import routes from '@/routes'
const TerminalsPageHeader = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <PageHeader
      ghost={false}
      title={t('components.pages.terminals.list.pageHeader.title')}
      extra={[
        <Button key="action" type="primary" onClick={() => navigate(routes.createTerminal.path)}>
          {t('components.pages.terminals.list.pageHeader.new')}
        </Button>,
      ]}
    />
  )
}

TerminalsPageHeader.displayName = 'TerminalsPageHeader'
export default TerminalsPageHeader
