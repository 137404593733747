import { PageHeader } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const UpdateAnnouncementPageHeader = () => {
  const { t } = useTranslation()
  return <PageHeader ghost={false} title={t('components.pages.announcements.update.pageHeader.title')} />
}

UpdateAnnouncementPageHeader.displayName = 'UpdateAnnouncementPageHeader'
export default UpdateAnnouncementPageHeader
