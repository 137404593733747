import { Button, Form, Input, Spin } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { UpdateEmployeeRequest, UpdateEmployeeResponse } from '@/types/apis/employees'

export type UpdateEmployeeFormValue = Pick<UpdateEmployeeRequest, 'name' | 'code'>

type Props = {
  updateLoading: boolean
  getLoading: boolean
  getEmployeeResponse: UpdateEmployeeResponse | undefined
  onSubmit: (formValues: UpdateEmployeeFormValue) => void
}

const UpdateEmployeeForm = ({ updateLoading, getLoading, getEmployeeResponse, onSubmit }: Props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  useEffect(() => {
    if (getEmployeeResponse) {
      form.setFieldsValue({
        name: getEmployeeResponse.name,
        code: getEmployeeResponse.code,
      })
    }
  }, [form, getEmployeeResponse])

  return (
    <Spin spinning={getLoading}>
      <Form layout="vertical" onFinish={onSubmit} form={form}>
        <Form.Item
          label={t('components.pages.employees.update.form.name')}
          name="name"
          rules={[{ required: true, message: t('components.pages.employees.update.form.requireName') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t('components.pages.employees.update.form.code')}
          name="code"
          rules={[{ required: true, message: t('components.pages.employees.update.form.requireCode') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={updateLoading}>
            {t('components.pages.employees.update.form.update')}
          </Button>
        </Form.Item>
      </Form>
    </Spin>
  )
}

UpdateEmployeeForm.displayName = 'UpdateEmployeeForm'
export default UpdateEmployeeForm
