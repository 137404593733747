import { Button, Checkbox, Popconfirm, Space, Table, TablePaginationConfig } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import routes from '@/routes'
import { Language } from '@/types'

type Props = {
  dataSource: Language[] | undefined
  getLanguagesLoading: boolean
  onChange: (config: TablePaginationConfig) => void
  onDelete: (id: Language['id']) => void
}

const LanguagesTable = ({ dataSource, getLanguagesLoading, onChange, onDelete }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [visibleConfirmDelete, setVisibleConfirmDelete] = useState<Record<string, boolean>>({})

  const columns = [
    {
      title: t('components.pages.languages.list.table.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('components.pages.languages.list.table.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('components.pages.languages.list.table.code'),
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: t('components.pages.languages.list.table.isDefault'),
      dataIndex: 'isDefault',
      key: 'isDefault',
      render: (text: boolean, record: Language) => <Checkbox checked={record.isDefault} />,
    },
    {
      title: t('components.pages.languages.list.table.action'),
      dataIndex: 'action',
      key: 'action',
      align: 'right' as const,
      render: (text: string, record: Language) => (
        <Space size="middle">
          <Button type="link" size="small" onClick={() => goToEditLanguagePage(record.id)}>
            {t('components.pages.languages.list.table.update')}
          </Button>
          <Popconfirm
            id={record.id.toString()}
            visible={!!visibleConfirmDelete[record.id]}
            title={t('components.pages.languages.list.table.confirmDelete')}
            okText={t('common.yes')}
            cancelText={t('common.no')}
            onConfirm={() => onDelete(record.id)}
            onCancel={() => setVisibleConfirmDelete({})}
          >
            <Button type="link" size="small" danger onClick={() => setVisibleConfirmDelete({ [record.id]: true })}>
              {t('components.pages.languages.list.table.delete')}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ]

  const goToEditLanguagePage = (id: Language['id']) => {
    navigate(`${routes.languages.path}/${id}`)
  }

  return (
    <Table
      rowKey="id"
      dataSource={dataSource}
      columns={columns}
      loading={getLanguagesLoading}
      pagination={false}
      onChange={onChange}
    />
  )
}

LanguagesTable.displayName = 'LanguagesTable'
export default LanguagesTable
