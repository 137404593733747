import { VFC } from 'react'

import { ImportUniformsPageHeader, ImportUniformsUploadCvs } from '@/components/pages/Uniforms/Import'
import { useImportUniforms } from '@/pages/Uniforms/Import/Import.hooks'

const ImportUniforms: VFC = () => {
  const { importUniforms, loading } = useImportUniforms()

  return (
    <>
      <ImportUniformsPageHeader />
      <ImportUniformsUploadCvs loading={loading} onUpload={importUniforms} />
    </>
  )
}

ImportUniforms.displayName = 'ImportUniforms'
export default ImportUniforms
