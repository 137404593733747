export const update = {
  form: {
    name: 'Name',
    code: 'Code',
    update: 'Update',
    requireName: 'Please input employee name!',
    requireCode: 'Please input employee code!',
  },
  pageHeader: {
    title: 'Update employee',
  },
}
