import 'moment/locale/ja'

import React from 'react'
import { Route, Routes } from 'react-router-dom'

import MainLayout from '@/components/Layout/Layout'
import LanguageContextProvider from '@/contexts/Language'
import routes from '@/routes'

function App() {
  return (
    <LanguageContextProvider>
      <MainLayout>
        <Routes>
          {Object.values(routes).map((route, index) => {
            const Component = route.component
            return <Route key={index} path={route.path} element={<Component />} />
          })}
        </Routes>
      </MainLayout>
    </LanguageContextProvider>
  )
}

App.displayName = 'App'
export default App
