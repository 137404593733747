import './Layout.css'

import {
  GlobalOutlined,
  IdcardOutlined,
  MacCommandOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  NotificationOutlined,
  SkinOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons'
import { Dropdown, Image, Layout, Menu, Space } from 'antd'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import japanFlag from '@/assets/images/flags/japan.png'
import usaFlag from '@/assets/images/flags/usa.png'
import Language from '@/constants/language'
import useLanguage from '@/hooks/useLanguage'
import routes from '@/routes'

const { Header, Sider, Content } = Layout

const MainLayout: FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false)
  const [language, setLanguage] = useLanguage()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  const selectedKey = '/' + pathname?.split('/')[1]

  const languages = {
    [Language.English]: { id: Language.English, name: t('locales.english'), flag: usaFlag },
    [Language.Japanese]: { id: Language.Japanese, name: t('locales.japanese'), flag: japanFlag },
  }

  const menus = [
    { name: t('components.layout.employees'), icon: <UserOutlined />, link: routes.employees.path },
    { name: t('components.layout.idCards'), icon: <IdcardOutlined />, link: routes.idCards.path },
    { name: t('components.layout.terminals'), icon: <MacCommandOutlined />, link: routes.terminals.path },
    { name: t('components.layout.uniforms'), icon: <SkinOutlined />, link: routes.uniforms.path },
    { name: t('components.layout.attendanceLogs'), icon: <UserSwitchOutlined />, link: routes.attendanceLogs.path },
    { name: t('components.layout.languages'), icon: <GlobalOutlined />, link: routes.languages.path },
    { name: t('components.layout.announcements'), icon: <NotificationOutlined />, link: routes.announcements.path },
  ]

  const languageOverlay = (
    <Menu>
      {Object.values(languages).map((language) => (
        <Menu.Item key={language.id} onClick={() => setLanguage(language.id)}>
          <Image preview={false} width={30} height={30} src={language.flag} />
          <span style={{ marginLeft: 10 }}>{language.name}</span>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="logo" />
        <Menu theme="dark" mode="inline" activeKey={selectedKey} selectedKeys={[selectedKey]}>
          {menus.map((menu) => (
            <Menu.Item key={menu.link} icon={menu.icon}>
              <Link to={menu.link}>{menu.name}</Link>
            </Menu.Item>
          ))}
        </Menu>
        <Space
          style={{ width: 100 + '%', position: 'absolute', bottom: 20, justifyContent: 'center', cursor: 'pointer' }}
          align="center"
          size="middle"
        >
          <Dropdown overlay={languageOverlay} trigger={['click']}>
            <div>
              <Image preview={false} width={30} height={30} src={languages[language].flag} />
              <span style={{ color: 'white', marginLeft: 10 }}>{languages[language].name}</span>
            </div>
          </Dropdown>
        </Space>
      </Sider>

      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggle,
          })}
        </Header>

        <Content
          className="site-layout-background"
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

MainLayout.displayName = 'MainLayout'
export default MainLayout
