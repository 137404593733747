import { Table, TablePaginationConfig } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Uniform } from '@/types'

type Props = {
  dataSource: Uniform[]
  getUniformsLoading: boolean
  page: number
  pageSize: number
  totalItems: number
  onChange: (config: TablePaginationConfig) => void
}

const UniformsTable = ({ dataSource, getUniformsLoading, page, pageSize, totalItems, onChange }: Props) => {
  const { t } = useTranslation()

  const columns = [
    {
      title: t('components.pages.uniforms.list.table.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('components.pages.uniforms.list.table.code'),
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: t('components.pages.uniforms.list.table.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('components.pages.uniforms.list.table.size'),
      dataIndex: 'size',
      key: 'size',
    },
  ]

  return (
    <Table
      rowKey="id"
      dataSource={dataSource}
      columns={columns}
      loading={getUniformsLoading}
      pagination={{
        pageSize,
        current: page,
        total: totalItems,
      }}
      onChange={onChange}
    />
  )
}

UniformsTable.displayName = 'UniformsTable'
export default UniformsTable
