export const create = {
  form: {
    newAnnouncement: 'New announcement',
    text: 'Text',
    startAt: 'Start At',
    endAt: 'End At',
    isQuestion: 'isQuestion',
    requireText: 'Please input announcement text!',
    requireStartAt: 'Please input announcement Start At field!',
    requireEndAt: 'Please input announcement End At field!',
    summit: 'Submit',
  },
  pageHeader: {
    title: 'Create announcement',
  },
}
