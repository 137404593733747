export const list = {
  table: {
    id: 'ID',
    code: 'Code',
    employee: 'Employee',
    terminal: 'Terminal',
    uniform: 'Uniform',
    createdAt: 'Created At',
    returnedAt: 'Returned At',
    action: 'Action',
    delete: 'Delete',
    btnReturn: 'Return',
    confirmDelete: 'Are you sure to delete this attendance log?',
  },
  pageHeader: {
    title: 'Attendance Logs ',
  },
}
