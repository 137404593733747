import { PageHeader } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

const CreateEmployeePageHeader = () => {
  const { t } = useTranslation()
  return <PageHeader ghost={false} title={t('components.pages.announcements.create.pageHeader.title')} />
}

CreateEmployeePageHeader.displayName = 'CreateEmployeePageHeader'
export default CreateEmployeePageHeader
