import axios, { AxiosResponse } from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://uniform-api-stg.herokuapp.com/',
})

const handleResponseSuccess = (response: AxiosResponse) => {
  return Promise.resolve(response.data)
}

// const handleResponseFailed = (error: AxiosError) => {
//   return Promise.reject(error.response?.data || { message: error.message })
// }

axiosInstance.interceptors.response.use(handleResponseSuccess)

export default axiosInstance
