import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Menu, PageHeader } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import routes from '@/routes'

const UniformsPageHeader = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => navigate(routes.uniformsImport.path)}>
        {t('components.pages.uniforms.list.pageHeader.csvImport')}
      </Menu.Item>
    </Menu>
  )

  return (
    <PageHeader
      ghost={false}
      title={t('components.pages.uniforms.list.pageHeader.title')}
      extra={[
        <Dropdown.Button
          key="action"
          type="primary"
          icon={<DownOutlined />}
          overlay={menu}
          onClick={() => navigate(routes.createUniform.path)}
        >
          {t('components.pages.uniforms.list.pageHeader.new')}
        </Dropdown.Button>,
      ]}
    />
  )
}

UniformsPageHeader.displayName = 'UniformsPageHeader'
export default UniformsPageHeader
