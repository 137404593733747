import {
  AttendanceLogReturnRequest,
  AttendanceLogReturnResponse,
  DeleteAttendanceLogRequest,
  DeleteAttendanceLogResponse,
  GetAttendanceLogsRequest,
  GetAttendanceLogsResponse,
} from '@/types/apis/attendanceLogs'

import axios from './axios'

const API_ENDPOINT = '/attendance-logs'

export const getAttendanceLogs = (request: GetAttendanceLogsRequest): Promise<GetAttendanceLogsResponse> => {
  return axios.get(API_ENDPOINT, { params: request })
}

export const deleteAttendanceLog = (request: DeleteAttendanceLogRequest): Promise<DeleteAttendanceLogResponse> => {
  return axios.delete(`${API_ENDPOINT}/${request.id}`)
}

export const updateAttendanceLogReturn = (
  request: AttendanceLogReturnRequest
): Promise<AttendanceLogReturnResponse> => {
  return axios.put(`${API_ENDPOINT}/${request.id}/return`, request)
}
