import { Button, DatePicker, Form, Modal, Space } from 'antd'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { AttendanceLog } from '@/types'
import { AttendanceLogReturnRequest } from '@/types/apis/attendanceLogs'

export type AttendanceLogReturnFormValue = Pick<AttendanceLogReturnRequest, 'returnedAt'>

type Props = {
  onClose: () => void
  isShow: boolean
  returnId: number
  onReturnSubmit: (formValues: AttendanceLogReturnFormValue, id: AttendanceLog['id']) => Promise<void>
  returnLoading: boolean
}

const AttendanceLogReturnForm = ({ isShow, onClose, returnId, onReturnSubmit, returnLoading }: Props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const onFinish = (formValues: AttendanceLogReturnFormValue) => {
    onReturnSubmit(formValues, returnId)
  }

  useEffect(() => {
    form.setFieldsValue({
      returnAt: moment(new Date()),
    })
  }, [form])
  return (
    <Modal
      forceRender
      onCancel={onClose}
      title={t('components.pages.attendanceLogs.modalReturn.modal.titleModal')}
      visible={isShow}
      footer={null}
    >
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <Form.Item label={t('components.pages.attendanceLogs.modalReturn.modal.returnedAt')} name="returnAt">
          <DatePicker showTime></DatePicker>
        </Form.Item>
        <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button loading={returnLoading} type="primary" danger htmlType="submit">
            {t('components.pages.attendanceLogs.modalReturn.modal.btnReturn')}
          </Button>
          <Button onClick={onClose}>{t('components.pages.attendanceLogs.modalReturn.modal.btnCancel')}</Button>
        </Space>
      </Form>
    </Modal>
  )
}

AttendanceLogReturnForm.displayName = 'AttendanceLogReturnForm'
export default AttendanceLogReturnForm
