import { TablePaginationConfig } from 'antd'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AttendanceLogsPageHeader, AttendanceLogsTable } from '@/components/pages/AttendanceLogs/List'
import { AttendanceLogReturnFormValue } from '@/components/pages/AttendanceLogs/ModalReturn/Modal'
import routes from '@/routes'
import { AttendanceLog } from '@/types'

import { useAttendanceLogs } from './AttendanceLogs.hooks'

const AttendanceLogs = () => {
  const navigate = useNavigate()
  const {
    getAttendanceLogs,
    deleteAttendanceLog,
    getAttendanceLogsLoading,
    getAttendanceLogsResponse,
    page,
    pageSize,
    setPage,
    updateReturnUniform,
    returnLoading,
  } = useAttendanceLogs()

  const dataSource = getAttendanceLogsResponse?.items ?? []
  const totalItems = getAttendanceLogsResponse?.meta.totalItems || 0

  const handleChangePage = (config: TablePaginationConfig) => {
    setPage(config.current ?? page)
    navigate({
      pathname: routes.attendanceLogs.path,
      search: `?page=${config.current ?? page}`,
    })
  }

  const handleDeleteAttendanceLog = async (id: AttendanceLog['id']) => {
    await deleteAttendanceLog({ id })
  }

  const handleSubmitForm = async (formValues: AttendanceLogReturnFormValue, id: AttendanceLog['id']) => {
    await updateReturnUniform({ ...formValues, id })
  }

  useEffect(() => {
    getAttendanceLogs().then()
  }, [getAttendanceLogs])

  return (
    <>
      <AttendanceLogsPageHeader />
      <AttendanceLogsTable
        {...{ getAttendanceLogsLoading, returnLoading, page, pageSize, totalItems, dataSource }}
        onChange={handleChangePage}
        onDelete={handleDeleteAttendanceLog}
        onReturnSubmit={handleSubmitForm}
      />
    </>
  )
}

AttendanceLogs.displayName = 'AttendanceLogs'
export default AttendanceLogs
