import { useCallback, useState } from 'react'

import { deleteEmployee, getEmployees } from '@/apis/employees'
import { API_LIMIT } from '@/constants/api'
import useQueryPage from '@/hooks/useQueryPage'
import { DeleteEmployeeRequest, GetEmployeesResponse } from '@/types/apis/employees'

export const useEmployees = () => {
  const currentPage = useQueryPage()
  const [page, setPage] = useState(currentPage)
  const [getEmployeesLoading, setGetEmployeesLoading] = useState(false)
  const [deleteEmployeeLoading, setDeleteEmployeeLoading] = useState(false)
  const [getEmployeesResponse, setEmployeesResponse] = useState<GetEmployeesResponse>()

  const get = useCallback(async () => {
    try {
      setGetEmployeesLoading(true)
      const res = await getEmployees({ page, limit: API_LIMIT })
      setEmployeesResponse(res)
    } finally {
      setGetEmployeesLoading(false)
    }
  }, [page])

  const del = useCallback(
    async ({ id }: DeleteEmployeeRequest) => {
      try {
        setDeleteEmployeeLoading(true)
        await deleteEmployee({ id })

        if (getEmployeesResponse?.items) {
          const newEmployees = getEmployeesResponse.items.filter((item) => item.id !== id)
          setEmployeesResponse({ ...getEmployeesResponse, items: newEmployees })
        }
      } finally {
        setDeleteEmployeeLoading(false)
      }
    },
    [getEmployeesResponse]
  )

  return {
    getEmployeesLoading,
    deleteEmployeeLoading,
    getEmployees: get,
    deleteEmployee: del,
    page,
    pageSize: API_LIMIT,
    setPage,
    getEmployeesResponse,
  }
}
