import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getAnnouncement, updateAnnouncement } from '@/apis/announcements'
import { getEmployees } from '@/apis/employees'
import { getLanguages } from '@/apis/languages'
import { API_LIMIT } from '@/constants/api'
import useNotification from '@/hooks/useNotification'
import useQueryPage from '@/hooks/useQueryPage'
import {
  GetAnnouncementRequest,
  GetAnnouncementResponse,
  UpdateAnnouncementRequest,
  UpdateAnnouncementResponse,
} from '@/types/apis/announcements'
import { GetEmployeesResponse } from '@/types/apis/employees'
import { GetLanguagesResponse } from '@/types/apis/languages'

export const useUpdateAnnouncements = () => {
  const openNotification = useNotification()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<UpdateAnnouncementResponse>()

  const update = useCallback(
    async (request: UpdateAnnouncementRequest) => {
      try {
        setLoading(true)

        const res = await updateAnnouncement(request)
        setResponse(res)
        openNotification({ description: t('pages.announcements.update.updateSuccessfully') })
      } finally {
        setLoading(false)
      }
    },
    [openNotification, t]
  )

  return {
    loading,
    updateAnnouncement: update,
    response,
  }
}

export const useGetAnnouncement = () => {
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<GetAnnouncementResponse>()

  const get = useCallback(async (request: GetAnnouncementRequest) => {
    try {
      setLoading(true)
      const res = await getAnnouncement(request)
      setResponse(res)
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    getAnnouncement: get,
    response,
  }
}

export const useGetEmployees = () => {
  const currentPage = useQueryPage()
  const [page] = useState(currentPage)
  const [getEmployeesResponse, setEmployeesResponse] = useState<GetEmployeesResponse>()

  const get = useCallback(async () => {
    const res = await getEmployees({ page, limit: API_LIMIT })
    setEmployeesResponse(res)
  }, [page])

  return {
    getEmployees: get,
    getEmployeesResponse,
  }
}

export const useGetLanguages = () => {
  const [getLanguagesResponse, setLanguagesResponse] = useState<GetLanguagesResponse>()

  const get = useCallback(async () => {
    const res = await getLanguages({})
    setLanguagesResponse(res)
  }, [])

  return {
    getLanguages: get,
    getLanguagesResponse,
  }
}
