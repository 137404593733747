import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { getEmployee, updateEmployee } from '@/apis/employees'
import useNotification from '@/hooks/useNotification'
import {
  GetEmployeeRequest,
  GetEmployeeResponse,
  UpdateEmployeeRequest,
  UpdateEmployeeResponse,
} from '@/types/apis/employees'

export const useUpdateEmployees = () => {
  const { t } = useTranslation()
  const openNotification = useNotification()
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<UpdateEmployeeResponse>()

  const update = useCallback(
    async (request: UpdateEmployeeRequest) => {
      try {
        setLoading(true)
        const res = await updateEmployee(request)
        setResponse(res)
        openNotification({ description: t('pages.employees.update.updateSuccessfully') })
      } finally {
        setLoading(false)
      }
    },
    [openNotification, t]
  )

  return {
    loading,
    updateEmployee: update,
    response,
  }
}

export const useGetEmployee = () => {
  const [loading, setLoading] = useState(false)
  const [response, setResponse] = useState<GetEmployeeResponse>()

  const get = useCallback(async (request: GetEmployeeRequest) => {
    try {
      setLoading(true)
      const res = await getEmployee(request)
      setResponse(res)
    } finally {
      setLoading(false)
    }
  }, [])

  return {
    loading,
    getEmployee: get,
    response,
  }
}
