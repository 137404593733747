import { PageHeader } from 'antd'
import { useTranslation } from 'react-i18next'

const UpdateUniformPageHeader = () => {
  const { t } = useTranslation()
  return <PageHeader ghost={false} title={t('components.pages.uniforms.update.pageHeader.title')} />
}

UpdateUniformPageHeader.displayName = 'UpdateUniformPageHeader'
export default UpdateUniformPageHeader
