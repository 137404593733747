import { Button, Popconfirm, Space, Table, TablePaginationConfig } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import routes from '@/routes'
import { Employee } from '@/types'

type Props = {
  dataSource: Employee[] | undefined
  getEmployeesLoading: boolean
  page: number
  pageSize: number
  totalItems: number
  onChange: (config: TablePaginationConfig) => void
  onDelete: (id: Employee['id']) => void
}

const EmployeesTable = ({ dataSource, getEmployeesLoading, page, pageSize, totalItems, onChange, onDelete }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [visibleConfirmDelete, setVisibleConfirmDelete] = useState<Record<string, boolean>>({})

  const columns = [
    {
      title: t('components.pages.employees.list.table.id'),
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: t('components.pages.employees.list.table.name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('components.pages.employees.list.table.code'),
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: t('components.pages.employees.list.table.action'),
      dataIndex: 'action',
      key: 'action',
      align: 'right' as const,
      render: (text: string, record: Employee) => {
        return (
          <Space size="middle">
            <Button type="link" size="small" onClick={() => goToEditEmployeePage(record.id)}>
              {t('components.pages.employees.list.table.update')}
            </Button>
            <Popconfirm
              id={record.id.toString()}
              visible={!!visibleConfirmDelete[record.id]}
              title={t('components.pages.employees.list.table.confirmDelete')}
              okText={t('common.yes')}
              cancelText={t('common.no')}
              onConfirm={() => onDelete(record.id)}
              onCancel={() => setVisibleConfirmDelete({})}
            >
              <Button type="link" size="small" danger onClick={() => setVisibleConfirmDelete({ [record.id]: true })}>
                {t('components.pages.employees.list.table.delete')}
              </Button>
            </Popconfirm>
          </Space>
        )
      },
    },
  ]

  const goToEditEmployeePage = (id: Employee['id']) => {
    navigate(`${routes.employees.path}/${id}`)
  }

  return (
    <Table
      rowKey="id"
      dataSource={dataSource}
      columns={columns}
      loading={getEmployeesLoading}
      pagination={{
        pageSize,
        current: page,
        total: totalItems,
      }}
      onChange={onChange}
    />
  )
}

EmployeesTable.displayName = 'EmployeesTable'
export default EmployeesTable
