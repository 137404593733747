export const list = {
  table: {
    id: 'ID',
    name: 'Name',
    code: 'Code',
    action: 'Action',
    update: 'Update',
    delete: 'Delete',
    confirmDelete: 'Are you sure to delete this employee?',
  },
  pageHeader: {
    title: 'Employees',
    new: 'New',
    csvImport: 'CSV Import',
  },
}
