export const list = {
  table: {
    id: 'Id',
    text: 'Text',
    startAt: 'Start At',
    endAt: 'End At',
    public: 'Public',
    published: 'Published',
    question: 'Question',
    action: 'Action',
    update: 'Update',
    delete: 'Delete',
    confirmDelete: 'Are you sure to delete this employee?',
  },
  pageHeader: {
    title: 'Announcements',
    new: 'New',
  },
}
