import { layout } from '@/components/Layout/Layout.i18n.en'
import { pages as componentPages } from '@/components/pages/pages.i18n.en'
import { pages } from '@/pages/pages.i18n.en'

const translation = {
  translation: {
    components: {
      layout,
      pages: componentPages,
    },
    pages,
    locales: {
      english: 'English',
      japanese: 'Japanese',
    },
    common: {
      notification: 'Notification',
      yes: 'Yes',
      no: 'No',
    },
  },
}

export default translation
